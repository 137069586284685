import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import HomePage from './components/HomePage';
import ComingSoon from './components/ComingSoon';
import Article from './components/Article';

const Footer = styled.footer`
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: white;
  background-color: black;
  bottom: 0;
  left: 0;

  p {
    margin: 2px 0;
  }

  @media (max-width: 768px) {
    font-size: 10px;
    padding: 0.3rem;
  }
`;

function App() {
  return (
    <BrowserRouter 
      future={{ 
        v7_startTransition: true,
        v7_relativeSplatPath: true 
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/articles/:slug" element={<Article />} />
          </Routes>
        </div>

        <Footer>
          <p>© 2024 Information-company.com is an Infocom's brand. All rights reserved.</p>
          <p>Incorporated in the Grand Duchy of Luxembourg, Europe, RCS Registration: B279794</p>
        </Footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
