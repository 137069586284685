import { 
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Collapse,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import logo from './logo_white.svg';

const StyledLink = styled(Link)`
  color: #333;
  text-decoration: none;
  padding: 8px 16px;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #333;
    transition: width 0.3s;
  }
  
  &:hover::after {
    width: 100%;
  }
`;

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navLinks = [
    { to: 'https://www.financial-portfolio.io/', text: 'Finance' },
    { to: '/coming-soon', text: 'Law' },
    { to: 'https://info-com.io', text: 'Media Technology' },
    { to: '/coming-soon', text: 'News & Opinion' }
  ];

  return (
    <Box>
      <AppBar position="sticky" elevation={0} sx={{ bgcolor: 'rgba(0, 0, 0, 0.9)', borderBottom: '1px solid #555' }}>
        <Toolbar sx={{ justifyContent: 'space-between', px: { xs: 2, sm: 4 } }}>
          <Link to="/">
            <img src={logo} alt="IC Logo" style={{ height: '2.5rem' }} />
          </Link>

          {isMobile ? (
            <IconButton onClick={() => setIsOpen(!isOpen)}>
              <MenuIcon style={{ color: '#fff' }} />
            </IconButton>
          ) : (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {navLinks.map((link) => (
                <StyledLink key={link.to} to={link.to} style={{ color: '#fff' }}>
                  {link.text}
                </StyledLink>
              ))}
            </Box>
          )}
        </Toolbar>
        {isMobile && (
          <Collapse in={isOpen}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2, bgcolor: '#111' }}>
              {navLinks.map((link) => (
                <StyledLink key={link.to} to={link.to} style={{ color: '#fff' }} onClick={() => setIsOpen(false)}>
                  {link.text}
                </StyledLink>
              ))}
            </Box>
          </Collapse>
        )}
      </AppBar>
    </Box>
  );
};
