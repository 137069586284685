import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeSlug from 'rehype-slug';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import '../styles/Article.css';
import SocialMediaIcons from './SocialMediaIcons';
import { Navigation } from './Navbar';


const ArticleTitle = styled.h1`
  margin-bottom: 4rem;
`;


const ArticleWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
`;


const ArticleMeta = styled.div`
  display: flex;
  gap: 3rem;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    &:not(:last-child):after {
      margin-left: 0.5rem;
    }
  }
`;

const MetaSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const MetaLabel = styled.span`
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`;

const MetaValue = styled.div`
  font-size: 1rem;
  color: #333;
`;


const AuthorName = styled.a`
  color: #83A6CE;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;


function Article() {
  const [content, setContent] = useState('');

  const { slug } = useParams();

  const [articleData, setArticleData] = useState({
    title: '',
    author: '',
    date: '',
    linkedin: ''
  });

  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    fetch(`/articles/${slug}.md`)
      .then((response) => {

        if (!response.ok) {
          throw new Error('Article not found');
        }
        return response.text();
      })
      .then((text) => {
        const [frontmatter, content] = text.split('---').filter(Boolean);
        console.log("content: ", content)

        const metadata = frontmatter.split('\n').reduce((acc, line) => {
          const [key, value] = line.split(': ');
          if (key?.trim() === 'linkedin') {
            // Remove any leading/trailing quotes or whitespace
            acc[key.trim()] = value.trim().replace(/['"]/g, '');
          } else if (key && value) {
            acc[key.trim()] = value.trim();
          }
          return acc;
         }, {});

        setContent(content);
        setArticleData({
          title: metadata.title,
          author: metadata.author,
          date: metadata.date,
          linkedin: metadata.linkedin
        });

      })
  }, [slug]);

  if (notFound) {
    setNotFound(true)
    return <div>Article not found</div>;
  }

  return (
    <div>
      <Navigation />
      <SocialMediaIcons />
      <ArticleWrapper>
        <ArticleTitle>{articleData.title}</ArticleTitle>
        <ArticleMeta>
          <MetaSection>
            <MetaLabel>Author(s)</MetaLabel>
            <AuthorName
            href={`https://${articleData.linkedin}`} 
            target="_blank" 
            rel="noopener noreferrer"
            >
            {articleData.author}
            </AuthorName>
          </MetaSection>
          <MetaSection>
            <MetaLabel>Published on</MetaLabel>
            <MetaValue>{articleData.date}</MetaValue>
          </MetaSection>
        </ArticleMeta>
        <div className="article-container">
          <ReactMarkdown rehypePlugins={[rehypeSlug]}>
            {content}
          </ReactMarkdown>
        </div>
      </ArticleWrapper>
    </div>
  );
}

export default Article;
